import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from 'framer-motion'
import "./layout.css"

const Layout = ({ children, home }) => {
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 2500)
  }, []);

  const transition = { duration: 1.5, yoyo: Infinity, ease: "easeInOut" }

  return (
    <div>
      {(home && spinner) ?
        <LoaderStyled className="container-loader">

          <svg id="Grupo_1" data-name="Grupo 1" xmlns="http://www.w3.org/2000/svg" width="97.799" height="37.346" viewBox="0 0 97.799 37.346">
            <defs>
              <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#fff" />
                <stop offset="1" stop-color="#838383" />
              </linearGradient>
              <linearGradient id="linear-gradient-8" x1="0" y1="0.5" x2="1" y2="0.5" />
            </defs>
            <g id="Grupo_1-2" data-name="Grupo 1" transform="translate(64.872)">
              <motion.path fill="transparent" strokeWidth={'1px'}
                stroke="rgba(255, 255, 255, 0.69)" strokeLinecap="round" initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={transition}
                id="Caminho_1" data-name="Caminho 1" d="M-173.575,93.474a6.321,6.321,0,0,1,2.9,1.827,3.5,3.5,0,0,1,.879,2.375,6.812,6.812,0,0,1-.3,1.631l-.069.179-1.7,3.875a6.245,6.245,0,0,1-2.6,3.306c-1.555,1.01-3.191.876-4.908.876l-15.863.051a.749.749,0,0,1-.606-.25.507.507,0,0,1-.121-.325.981.981,0,0,1,.03-.225l.277-.84a1.157,1.157,0,0,1,.485-.589,1.368,1.368,0,0,1,.758-.238l12.376.044c.058,0,.123,0,.19-.008a2.836,2.836,0,0,0,2.352-1.737l1.633-3.8a.915.915,0,0,0-.547-1.2l-7.125-2.623-1.619-.6c-1.883-.618-2.577-2.807-1.882-4.766l.172-.485,1.273-3.121.741-1.526c1.06-2.112,2.673-3.408,5.036-3.371.214,0,1.127,0,1.344,0l16.675.019a.793.793,0,0,1,.606.225.594.594,0,0,1,.152.4.682.682,0,0,1-.031.2l-.265.667a1.008,1.008,0,0,1-.455.563,1.36,1.36,0,0,1-.757.238l-12.708,0a4.3,4.3,0,0,0-1.633.166,2.476,2.476,0,0,0-1.076,1.459l-1.731,4.012a.514.514,0,0,0,.294.686l7.823,2.9" transform="translate(195.963 -81.94)" />
            </g>
            <g id="Grupo_5" data-name="Grupo 5" transform="translate(0 0.079)">
              <g id="Grupo_3" data-name="Grupo 3" transform="translate(5.056)">
                <g id="Grupo_2" data-name="Grupo 2">
                  <motion.path fill="transparent" strokeWidth={'1px'}
                    stroke="rgba(255, 255, 255, 0.69)" strokeLinecap="round" initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={transition}
                    id="Caminho_2" data-name="Caminho 2" d="M-279.937,84.921a4.661,4.661,0,0,1,4.289-2.835h4.9a.92.92,0,0,1,.586.158.329.329,0,0,1,.111.246.57.57,0,0,1-.028.175l-10.351,24.362a.928.928,0,0,1-.446.4,1.6,1.6,0,0,1-.726.167h-7.059l-.9-.016,4.919-11.585h-7.89a1.473,1.473,0,0,0-1.377.666l-4.4,10.373a.928.928,0,0,1-.446.4,1.54,1.54,0,0,1-.7.167h-7.087l-.874-.016,9.007-21.3.72-1.738a3.964,3.964,0,0,1,3.663-2.449h5.424a.87.87,0,0,1,.558.158.33.33,0,0,1,.112.246.587.587,0,0,1-.028.175L-292.654,93.7h7.961a1.379,1.379,0,0,0,1.287-.623l2.9-6.83Z" transform="translate(307.422 -82.086)" />
                  <motion.path fill="transparent" strokeWidth={'1px'}
                    stroke="rgba(255, 255, 255, 0.69)" strokeLinecap="round" initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={transition}
                    id="Caminho_3" data-name="Caminho 3" d="M-240.351,85.575a5.409,5.409,0,0,1,5.056-3.489h20.52a.868.868,0,0,1,.558.158.33.33,0,0,1,.112.246.587.587,0,0,1-.028.175l-.474,1.143a.973.973,0,0,1-.446.4,1.483,1.483,0,0,1-.7.175h-13.222a4.089,4.089,0,0,0-1.622.377,1.612,1.612,0,0,0-.781.765l-7.952,18.65a.891.891,0,0,0-.056.3.793.793,0,0,0,.321.554,1.59,1.59,0,0,0,1.074.29h13.337a.864.864,0,0,1,.558.158.328.328,0,0,1,.112.246.521.521,0,0,1-.028.158l-.474,1.143a.9.9,0,0,1-.447.413,1.541,1.541,0,0,1-.7.167h-18.387l-5.719-.042,9.13-21.325Z" transform="translate(280.693 -82.086)" />
                </g>
              </g>
            </g>
          </svg>

        </LoaderStyled>
        :
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>
            <main>
              {children}
            </main>
          </motion.div>
        </>

      }
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const LoaderStyled = styled.div`
height: 100vh;
width: 100vw;
margin: auto;
text-align: center;
align-content: center;
align-items: center;
align-self: center;
alignment-baseline: central;
display: flex;
svg {
  margin: auto;
  width: auto;
  height: 15vh;
  min-height: 15vh;
}

`