import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"
import logoFace from "../../images/global/social/FACEBOOK.svg"
import logoInsta from "../../images/global/social/INSTAGRAM.svg"
import logoLinked from "../../images/global/social/LINKEDIN.svg"
import logoTube from "../../images/global/social/YOUTUBE.svg"

import { InView } from 'react-intersection-observer';

import { useAnimation } from 'framer-motion';

const Footer = ({ data, long, setIsOpen, floatButtons, stand }) => {
  const [footerInView, setFooterInView] = useState(false);
  const controls = useAnimation();
  useEffect(() => {
    if (footerInView) {
      controls.start('visible');
    }
    if (!footerInView) {
      controls.start('hidden');
    }
  }, [footerInView, controls]);

  function handleClick(url, stand) {
    if (url.includes('form')) {
      if (stand) {
        setIsOpen(true)
      } else {
        const element = document.getElementById("form");
        if (element) {
          navigate(url)
        } else {
          navigate('/contactos' + url)
        }
      }
    }
    else if (stand) {
      window.open("tel:+351912091667", '_self', 'noreferrer') // stand's number
    } else {
      navigate(url)
    }
  }

  return (
    <>
      <InView as="div" className="container-car" threshold={0.15} onChange={inViewDiv => {
        setFooterInView(inViewDiv)
      }}>
        <StyledFooter long={long}>
          <div className="footer-links">
            <Link to={data.linksLeft[0].link} className={font.oR + " small"}>
              {data.linksLeft[0].linkTxt}
            </Link>
            <Link to={data.linksRight[1].link} className={font.oR + " small"}>
              {data.linksRight[1].linkTxt}
            </Link>
          </div>
          <div className="footer-links">
            <div className="social-links">
              <a href="https://www.instagram.com/hcs_performance/" rel="noreferrer" target="_blank">
                <img src={logoInsta} alt="Link do Instagram" />
              </a>
              <a href="https://www.facebook.com/hcsperformance.aveiro/" rel="noreferrer" target="_blank">
                <img src={logoFace} alt="Link do Facebook" />
              </a>
              <a href="https://www.linkedin.com/company/hcsperformance" rel="noreferrer" target="_blank">
                <img src={logoLinked} alt="Link do LinkedIn" />
              </a>
              <a href="https://www.youtube.com/channel/UCiabaDlm34ELeJBah87s2LQ" rel="noreferrer" target="_blank">
                <img src={logoTube} alt="Link do Youtube" />
              </a>
              <a
                href="https://livroreclamacoes.pt/inicio"
                target="_blank"
                alt="Livro de Reclamações"
                rel="noopener noreferrer"
              >
                <div className="livroreclamacoes-container">
                  <Img
                    fluid={data.image.childImageSharp.fluid}
                    alt="Livro de Reclamações"
                  />
                </div>
              </a>
            </div>
          </div>
        </StyledFooter>
      </InView>
      <StyledFloatButtons footerInView={footerInView ? '16vh': '2vh'}>
        {floatButtons.map((btn, index) => (
          <a onClick={() => handleClick(btn.btnLink, stand)} key={'floating-button' + index} className={index === 0 ? "master-container-svg-right" : "master-container-svg-left"}>
            <div dangerouslySetInnerHTML={{ __html: btn.btn }} className='container-svg'></div>
          </a>
        ))}
      </StyledFloatButtons>
    </>
  )
}

export default Footer

const StyledFloatButtons = styled.div`
  position: fixed;
  bottom: ${props => props.footerInView};
  right: 0;
  z-index: 100;
  width: auto;
  text-align: center;
  display: block;
  margin: auto;
  padding-right: 2vh;
  transition: bottom 0.1s linear;
  .master-container-svg-right{
    margin: auto;
    margin-bottom: 2vh;
  }
  .master-container-svg-left{
    margin: auto;
  }
  .container-svg{
    //background-color: black;
    background-color: #ab2c29;
    width: 7vh;
    border-radius: 50px;
    text-align: center;
    height: 7vh;
    align-content: center;
    align-items: center;
    display: grid;
    margin: auto;
    svg path{
      fill: #fff !important;
    }
    :hover{
      transition: all 0.5s ease;
      // background-color: #ab2c29;
      background-color: #ffffff;
      svg path{
        // fill: #F2F2F2 !important;
        fill: #ab2c29 !important;
        transition: all 0.5s ease;
      }
    }
    svg{
      margin: auto;
      height: 2.5vh;
    }
  }
`

const StyledFooter = styled.footer`
  ${props =>
    props.long &&
    css`
      position: relative;
    `}
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  padding: 2em 0;
  background-color: #0f0f0f;
  display: flex;
  justify-content: space-between;
  position: relative;
  .footer-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 130px;
    img {
      width: 100%;
    }
  }
  .footer-links {
    display: flex;
    align-items: center;
    color: #ffffff;
    max-width: 70%;
    a {
      margin: 0 0 0 6vw;
      font-size: 7px;
    }
  }
  .social-links {
    display: flex;
    align-items: center;
    margin: 0 6vw 0 0;
    img {
      height: 1.5em;
    }
    a {
      margin: 0 0 0 2em;
    }
  }
  .livroreclamacoes-container {
    width: 5rem;
  }
`
