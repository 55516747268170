import styled, { css } from "styled-components"
import React from "react"

export const Button = styled.button`
  background: transparent;
  user-select: none;
  outline: none;
  cursor: pointer;
`
export const Buttons = ({ text, btnClass, actionFunction, big }) => {
  if (btnClass === 'btn-grey')
    return (
      <ButtonGreyStyled btnBig={big}>
        <a className="button" onClick={actionFunction} >
          <svg xmlns="http://www.w3.org/2000/svg" width="209.58" height="42.233" viewBox="0 0 209.58 42.233">
            <g id="Grupo_6" data-name="Grupo 6" transform="translate(-1085.423 -879.999)">
              <g id="Grupo_420" data-name="Grupo 420" transform="translate(50 -2)">
                <path id="Caminho_252" data-name="Caminho 252" d="M274.762,981.1a6.94,6.94,0,0,0,1.309-.212,3.3,3.3,0,0,0,1.652-.971,3.066,3.066,0,0,0,.49-2.8L271.8,952.788c-1.894-7.194-10.619-11.831-22.1-11.747l-13.464.1-165.208.349.921,3.947,8.377,31.783a5.074,5.074,0,0,0,2.036,2.807,6,6,0,0,0,3.4,1.247Z" transform="translate(965.65 -58.041)" fill="none" stroke="#ab2c29" strokeWidth="2" />
              </g>
            </g>
          </svg>
          <span className=" intro-text uppercase"> {text}</span>
        </a>
      </ButtonGreyStyled>
    )
  else if (btnClass === 'btn-black-border-red')
    return (
      <ButtonBlackBorderRedStyled btnBig={big}>
        <a className="button" onClick={actionFunction} >
          <svg xmlns="http://www.w3.org/2000/svg" width="209.58" height="42.233" viewBox="0 0 209.58 42.233">
            <g id="Grupo_6" data-name="Grupo 6" transform="translate(-1085.423 -879.999)">
              <g id="Grupo_420" data-name="Grupo 420" transform="translate(50 -2)">
                <path id="Caminho_252" data-name="Caminho 252" d="M274.762,981.1a6.94,6.94,0,0,0,1.309-.212,3.3,3.3,0,0,0,1.652-.971,3.066,3.066,0,0,0,.49-2.8L271.8,952.788c-1.894-7.194-10.619-11.831-22.1-11.747l-13.464.1-165.208.349.921,3.947,8.377,31.783a5.074,5.074,0,0,0,2.036,2.807,6,6,0,0,0,3.4,1.247Z" transform="translate(965.65 -58.041)" fill="none" stroke="#ab2c29" strokeWidth="2" />
              </g>
            </g>
          </svg>
        <span className=" intro-text uppercase"> {text}</span>
        </a>
      </ButtonBlackBorderRedStyled>
    )

  else
    return (
      <div></div>
    )
}


const ButtonBlackBorderRedStyled = styled.div`
height: 4.5vh;
width: 100%;
position: relative;
${props => props.btnBig && css`
 height:100% !important;
  `}


 

span{
  text-align: center;
  height: 100%;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  display: grid;  
  z-index: 99;
  position: relative;
  transition: all 0.5s ease;
  color: #fff;

  position: absolute;
  top: 0;
  z-index: -99;
  text-align: center;
  width: 100%;
}

.button {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: block;
  line-height: 1.1;
  @media(max-width: 990px){
    width: 30vw;
    text-align: center;
    // padding: 2vh;
    height: 4.5vh;
    width: 100%;
    left: 0;
  }
  
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all  0.5s ease;
}

path {
  strokeWidth: 2;
  stroke: #AB2C29;
  fill: transparent;
  transition: all  0.5s ease;
}


.button:hover {
  cursor: pointer;
  span{
    color: #000;
  }
}

svg:hover path, span:hover + svg path, .button:hover svg path{
  stroke: #AB2C29;
  fill: #AB2C29;
}

svg:hover intro-text, intro-text:hover, .button:hover intro-text{
  color: #fff !important;
  z-index: 99;
}

svg:hover + span, p:hover, .button:hover span{
  color: #fff !important;
  z-index: 99;
}

.intro-text{
  color: #fff !important;
  margin: 0 !important;
}

.button:hover + span{
  z-index: 99;
}


`
const ButtonGreyStyled = styled.div`
height: 4.5vh;
    width: 100%;
    ${props => props.btnBig && css`
    height: 2.8em !important;
     `}
span{
  text-align: center;
  height: 100%;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  display: grid;  
  z-index: 99;
  position: relative;
  transition: all 0.5s ease;
  color: #000;
}

.button {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: block;
  line-height: 1.1;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all  0.5s ease;
}

path {
  strokeWidth: 2;
  stroke: #F2F2F2;
  fill: #F2F2F2;
  transition: all  0.5s ease;
}


.button:hover {
  cursor: pointer;
  span{
    color: #000;
  }
}

svg:hover path, span:hover + svg path, .button:hover svg path{
  stroke: #AB2C29;
  fill: #AB2C29;
}

svg:hover + span, span:hover, .button:hover span{
  color: #fff !important;
}

.intro-text{
  margin: 0 !important;
}

@media(max-width:990px){
  height: 4.5vh;
    width: 100%;
}
`
