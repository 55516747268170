import React, { useState } from "react"
import { Link } from "gatsby"
import AnimateHeight from 'react-animate-height';
import useEventListener from "../../utils/use-event-listener"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import closeBtn from "../../images/global/menu/FECHAR_MENU.svg"
import openServices from "../../images/global/menu/ABRIR_SERVICOS.svg"
import closeServices from "../../images/global/menu/FECHAR_SERVICOS.svg"

const Sidemenu = ({ data, open, close, servicesOpen }) => {
  const [services, setServices] = useState(servicesOpen);

  useEventListener("mousedown", (e) => sair(e));
  function sair(e) {
    !document.querySelector('#sidemenu').contains(e.target) && close(false);
  }

  return (
    <StyledMenu style={{ transform: open ? "translateX(0)" : "translateX(100%)" }} id="sidemenu">
      <div className="content">
        <button className="close-btn" onClick={() => { close(!open) }}>
          <img src={closeBtn} alt="Botão de Fechar" />
        </button>
        {data.links.map((data, i) => (
          data.linkTxt !== "SERVIÇOS" ?
            <Link to={data.link} partiallyActive={true}  className={font.oEL + " menu-links"} activeClassName={font.oR + " menu-links-active"} key={"sidemenu-link-" + i}>
              {data.linkTxt}
              <hr />
            </Link>
            :
            < div className="div-services" key={"sidemenu-link-" + i}>
              <button 
                className={!services ? (font.oEL + " menu-links services-btn") : (font.oR + " menu-links services-btn menu-links-active")} onClick={() => { setServices(!services) }}>
                <div>
                  {data.linkTxt}
                  <img src={!services ? openServices : closeServices} alt="Botão de Abrir Serviços" />
                </div>
                <hr />
              </button>
              <AnimateHeight duration={600} height={services ? "auto" : 0} className="services" >
                {data.extraLinks.map((data, i) => (
                  <Link to={data.link} partiallyActive={true}  className={font.oEL + " menu-links small"} activeClassName={font.oR + " menu-links-active"} key={"sidemenu-services-link-" + i}>
                    {data.linkTxt}
                    <hr />
                  </Link>
                ))}
              </AnimateHeight>
            </div>
        ))}
      </div>
      <p className={font.oEL + " menu-copyright"}>CREATED: INVISUAL.PT</p>
    </StyledMenu>
  )
}


export default Sidemenu

const StyledMenu = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  width: 30vw;
  max-width: 400px;
  min-width: 300px;
  height: 100vh;
  @media only screen and (max-width: 768px){
    width: 90%;
    max-width: unset;
    min-width: unset;
  }
  transition: transform 600ms ease-out;
  background-color: #0f0f0f;
  color: #ffffff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content{
    position: relative;
    width: 70% !important;
    height: 70% !important;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8vh;
    @media only screen and (max-width: 768px){
      padding: 10vh 15% 10vh 25%;
    }
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .close-btn{
      position: absolute;
      top: -1.8vw;
      right: 1.7vw;
      @media only screen and (max-width: 768px){
        right: 7vw;
      }
      transform: translateY(-50%);
      height: 25px;
      @media only screen and (max-width: 500px){
        height: 18px;
        top: 30px;
      }
      img{height: 100%;}
    }
    .menu-links{
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      img{width: 1em; margin-left: 0.5em;}
      hr{
        width: 100%;
        color: #ffffff;
        margin-bottom: 0vh;
        margin-top: 1vh;
        border: 1px solid white;
      }
    }
    .menu-links-active{
      color: #ab2c29;
    }
    .services-btn{
      flex-direction: column;
      width: 100%;
    }
    .services-btn.menu-links-active{
      hr{
        width: 0;
        visibility: hidden;
        color: transparent;
      }
    }
    .services{
      transition: display 0.5s linear;
      div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
      }
      hr{visibility: hidden; margin-top: 0.3em;}
      .menu-links{
        height: 3em;
      }
      .menu-links-active{
        hr{
          visibility: visible;
          color: #ab2c29;
        }
      }
    }
  }
  .menu-copyright{
    position: relative;
    left: 15%;
    bottom: 3vw;
    width: fit-content;
    font-size: 8px;
  }

  @media (max-width: 990px){
    .div-services{
      padding-top: 2vh;
      padding-bottom: 1vh;
      
    }
    .content{
      padding: 0;
      width: 90% !important;
      height: 70vh !important;

      .menu-links{
        width: 80%;
        margin: auto;
      }

      .services{
        margin-top: 0;
        margin-bottom: 2vh;
      }

      .close-btn{
        top: -6%;
        right: 3vw;
      }
    }
  }
`